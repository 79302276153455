// import About from "./About";
// import Experience from "./Experience";

// const NavElements = [
//   {
//     name: "About",
//     path: () => {
//       return <About />;
//     },
//     dir: "/about",
//   },
//   {
//     name: "Experience",
//     path: () => {
//       return <Experience Exp={Exp} />;
//     },
//     dir: "/experience",
//   },
//   {
//     name: "About2",
//     path: () => {
//       return <About />;
//     },
//   },
// ];
import * as icons from "./Icons/index.ts";

const Exp = [
  {
    companyName: "The SparkFoundation",
    jobRole: "Web Developer",
    jobType: "internship",
    duration: "Feb 2021 - Mar 2021 • 1 Month",
    loc: "Remote, India",
    jobDesc: [
      "Created Bank application using Django.",
      "Responsible for creating maintaing and Displaying transactions.",
    ],
    position: 1,
  },
  {
    companyName: "The SparkFoundation",
    jobRole: "Web Developer",
    jobType: "internship",
    duration: "Feb 2021 - Mar 2021 • 1 Month",
    loc: "Remote, India",
    jobDesc: [
      "Created Bank application using Django.",
      "Responsible for creating maintaing and Displaying transactions.",
    ],
    position: -1,
  },
];

const UsingSvg = [
  {
    name: "Django",
    component: () => {
      return <icons.Django className="svgImg" />;
    },
  },
  {
    name: "Html5",
    component: () => {
      return <icons.Html5 className="svgImg" />;
    },
  },
  {
    name: "Js",
    component: () => {
      return <icons.Js className="svgImg" />;
    },
  },
  {
    name: "Github",
    component: () => {
      return <icons.Github className="svgImg" />;
    },
  },
];
const LearningSvg = [
  {
    name: "React",
    component: () => {
      return <icons.React className="svgImg" />;
    },
  },
  {
    name: "Unity",
    component: () => {
      return <icons.Unity className="svgImg" />;
    },
  },
];
const OtherSvg = [
  {
    name: "Python",
    component: () => {
      return <icons.Python className="svgImg" />;
    },
  },
  {
    name: "C++",
    component: () => {
      return <icons.C className="svgImg" />;
    },
  },
  {
    name: "CSharp",
    component: () => {
      return <icons.CSharpLogo className="svgImg" />;
    },
  },

  {
    name: "Flutter",
    component: () => {
      return <icons.Flutter className="svgImg" />;
    },
  },
  {
    name: "Java",
    component: () => {
      return <icons.Java className="svgImg" />;
    },
  },
  {
    name: "Mysql",
    component: () => {
      return <icons.MysqlLogo className="svgImg" />;
    },
  },
  {
    name: "Php",
    component: () => {
      return <icons.Php className="svgImg" />;
    },
  },
  {
    name: "Selenium",
    component: () => {
      return <icons.Selenium className="svgImg" />;
    },
  },
];

//-------------------------------------------------

const colleges = [
  {
    id: 1,
    clgName: "Sinhgad Academy Of Engineering",
    clgLoc: "Pune, Maharashtra, India",
    cource: "Information Technology",
    cgpa: "9.6 cgpa",
    year: "Aug, 2019 - Jul, 2022",
  },
  {
    id: 2,
    clgName: "Government Polytechnic",
    clgLoc: "Nashik, Maharashtra, India",
    cource: "Information Technology",
    cgpa: "72.63%",
    year: "Aug, 2017 - Jul, 2019",
  },
  {
    id: 3,
    clgName: "Chhatre New English School",
    clgLoc: "Manmad, Maharashtra, India",
    cource: "Science",
    cgpa: "58%",
    year: "Aug, 2016 - Jul, 2017",
  },
];

export { Exp, UsingSvg, LearningSvg, OtherSvg, colleges };

export const projects = [
  {
    id: "5",
    title: "Portfolio Web App",
    techStack: "React, html, css",
    desc: `This is my Intro site. Hope you Like what I do`,
    link: "",
  },

  {
    id: "4",
    title: "Challas Aath",
    techStack: ".NET, C# & UNITY",
    from: "12/2022",
    to: "present",
    desc: `It is a captivating 2D classic Indian board game project that
    reflects my game development skills. Designed with cultural
    significance, this game showcases historical strategy
    elements, promoting critical thinking and problem-solving
    abilities. Its engaging gameplay and cultural relevance make
    it an appealing addition to any gaming portfolio, highlighting
    my talent in creating immersive experiences.
    `,
    link: "",
  },
  {
    id: "3",
    title: "IOT based air pollution prediction using ML",
    techStack: "Python, Arduino, ML libs",
    from: "07/2021",
    to: "06/2022",
    desc: `This project aimed to develop an air pollution prediction and
    visualization system utilizing map integration. The project
    involved gathering data from various locations and
    employing SVM and Random Forest models for training. Data
    collection was facilitated through the utilization of Node MCU,
    MQ3, MQ6, and MQ7 sensors, along with a GPS module for
    accurate location data.
    `,
    link: "#",
  },
  {
    id: "2",
    title: "Online assignment submissions",
    techStack: "Mysql, C#, .NET",
    from: "10/2020",
    to: "12/2020",
    desc: `This project employed C#, MySQL, and .NET to create a userfriendly system for teachers and students. It enhanced
    teaching by enabling easy assignment creation, evaluations,
    and feedback. Students could submit assignments, resubmit
    work, and access valuable feedback. This project showcases
    my ability to optimize academic processes and foster
    collaboration.`,
    link: "#",
  },
  {
    id: "1",
    title: "Online doctor appointment booking",
    techStack: "Unity",
    from: "09/2018",
    to: "05/2019",
    desc: `This project is an online doctor appointment booking system
    developed using PHP and MySQL. It involves two main actors:
    patients and doctors. Patients can easily schedule
    appointments based on their preferred time, date, and doctor
    specialization. On the other hand, doctors have access to a
    convenient dashboard that displays the list of patients based
    on scheduled appointment times.`,
    link: "#",
  },
];
