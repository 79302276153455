import React from "react";
import "./Css/education.css";
// import { Container, Navbar, Button, NavItem } from "react-bootstrap";
const Experience = (props) => {
  return (
    <React.Fragment>
      {" "}
      <div className="heading expHead" style={{}}>
        <h1 className="headingText">Experience</h1>
        <div className="dash"></div>
      </div>
      <div className="ExperienceMainContainer">
        <div className="artEx">
          {props.Exp.map((item, index) => {
            const {
              companyName,
              jobRole,
              jobType,
              duration,
              loc,
              jobDesc,
              position,
            } = item;
            return (
              <div className="articleExperience" key={index}>
                <div className="jouneyContainer">
                  <div className={position === 1 ? "role first" : "role"}></div>
                  <div
                    className={position === -1 ? "journey last" : "journey"}
                  ></div>
                </div>

                <div className="journyInfo">
                  <p className="comapanyName">
                    <span>{companyName}</span>
                  </p>
                  <p className="jobRole">
                    <span>{jobRole}</span> ({jobType})
                  </p>
                  <p className="jobDuration">{duration}</p>
                  <p className="jobLocation">{loc}</p>
                  <ul>
                    {jobDesc.map((item, index) => (
                      <li key={index}>{item}</li>
                    ))}
                  </ul>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </React.Fragment>
  );
};

export default Experience;
