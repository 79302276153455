import * as React from "react";
import type { SVGProps } from "react";
const SvgPhp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    fill="none"
    viewBox="0 0 32 32"
    {...props}
  >
    <circle cx={16} cy={16} r={14} fill="#8892BF" />
    <path
      fill="#fff"
      d="M14.44 10h1.68l-.476 2.524h1.51c.828.018 1.445.203 1.85.555.414.353.536 1.022.366 2.01l-.816 4.4H16.85l.779-4.203c.08-.44.057-.753-.073-.938-.13-.185-.41-.278-.84-.278l-1.352-.013-.998 5.432h-1.68L14.44 10Z"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M6.74 12.524h3.264c.957.009 1.651.309 2.081.899.43.59.573 1.396.426 2.418a4.938 4.938 0 0 1-.377 1.375c-.187.45-.446.854-.779 1.216-.406.458-.84.748-1.303.872a5.55 5.55 0 0 1-1.436.185H7.155L6.692 22H5l1.74-9.476Zm.695 5.472.728-3.952h.246c.09 0 .182-.004.28-.013.65-.009 1.189.057 1.619.198.438.141.584.674.438 1.6-.178 1.1-.527 1.744-1.047 1.929-.52.176-1.168.26-1.948.25h-.17a.83.83 0 0 1-.146-.012ZM24.436 12.524h-3.262L19.433 22h1.692l.463-2.511h1.46a5.55 5.55 0 0 0 1.437-.185c.463-.123.897-.414 1.303-.872.333-.362.592-.767.779-1.216.195-.45.32-.908.377-1.375.146-1.022.005-1.828-.426-2.418-.43-.59-1.124-.89-2.082-.899Zm-1.84 1.52-.728 3.952a.83.83 0 0 0 .146.013h.17c.78.009 1.429-.075 1.948-.251.52-.185.869-.828 1.047-1.93.146-.925 0-1.458-.438-1.599-.43-.14-.97-.207-1.62-.198a3.15 3.15 0 0 1-.28.013h-.245Z"
      clipRule="evenodd"
    />
  </svg>
);
export default SvgPhp;
