// import logo from "./logo.svg";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

import React from "react";
import { Exp } from "./Component/variables";
import { Route, Routes, useLocation } from "react-router-dom";
import Experience from "./Component/Experience";
import About from "./Component/About";
import Navbar from "./Component/Navbar";
import Skills from "./Component/Skills";
import { Education } from "./Component/Education";
import { Projects } from "./Component/Projects";
import {
  faEnvelope,
  faBook,
  faRankingStar,
  faLaptopCode,
  faTableList,
  faUserCircle,
  faLink,
} from "@fortawesome/free-solid-svg-icons";
// import { faLaugh } from "@fortawesome/free-regular-svg-icons";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";

// import { faGearCode } from '@fortawesome/free-brands-svg-icons';
// import Test from "./Component/test";

// ------------------------------------------------------------------------------------------------

const navItems = [
  { path: "/about", value: "About", icon: faUserCircle },
  { path: "/experience", value: "Experience", icon: faRankingStar },
  { path: "/education", value: "Education", icon: faBook },
  { path: "/skill", value: "Skill", icon: faLaptopCode },
  { path: "/projects", value: "Projects", icon: faTableList },
  // { path: "/test", value: "Test", icon: faTableList },
];
const setPage = (page) => {
  curPage = page;
};
let curPage = "";

//---------------------------------------------------------------------------------------

function App() {
  const { pathname } = useLocation();
  const page = pathname === "/" ? "/about" : pathname;
  curPage = navItems.filter((items) => items.path === page)[0].value;
  return (
    <main className="main">
      <header>
        <Navbar NavElements={navItems} setPage={setPage} curPage={curPage} />
      </header>
      <article>
        <Routes>
          <Route
            path="/experience"
            element={<Experience Exp={Exp} />}
            exact
            key="exp"
          />
          ,
          <Route
            path="/about"
            element={
              <About
                icons={{
                  github: faGithub,
                  linkedIn: faLinkedin,
                  envelope: faEnvelope,
                }}
              />
            }
            exact
            strict
            key="about"
          />
          ,
          <Route
            path="/education"
            element={<Education />}
            exact
            strict
            key="eduction"
          />
          ,
          <Route
            path="/projects"
            element={<Projects icons={{ faLink }} />}
            exact
            strict
            key="projects"
          />
          ,
          <Route path="/skill" element={<Skills />} exact strict key="skill" />,
          {/* <Route path="/test" element={<Test />} /> */}
          <Route
            path="/"
            element={
              <About
                icons={{
                  github: faGithub,
                  linkedIn: faLinkedin,
                  envelope: faEnvelope,
                }}
              />
            }
          />
        </Routes>
      </article>
    </main>
  );
}

export default App;
