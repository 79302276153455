import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import { Container, Navbar, Button, NavItem } from "react-bootstrap";
const About = ({ icons }) => {
  return (
    <div className="articleBox">
      {/* <h1 className="topicTitle">About</h1> */}
      <div className="articleContent">
        <div className="profilePhotoDiv">
          <div className="ppCont">
            {" "}
            <img
              className="profilePhoto"
              src={require("./profilePhoto.jpeg")}
              alt="Profile Photo"
            />
          </div>
        </div>

        <div className="aboutInfo">
          <p>
            My name is Dinesh I'm a Software developer , Maharashtra, India. I
            have developed many types of front-ends, Back-ends as well as Games.
          </p>
          <p>I'm passionate about Technology & Revolution it does...</p>
          <div className="socialLinks">
            <a href="mailto:dineshnikam1999gmail.com" target="_blank">
              <FontAwesomeIcon
                className="iconsSocialLinks"
                icon={icons.envelope}
              />
            </a>
            <a href="https://github.com/DineshNikam/" target="_blank">
              <FontAwesomeIcon
                className="iconsSocialLinks"
                icon={icons.github}
              />
            </a>
            <a
              href="https://www.linkedin.com/in/dinesh-nikam-3471a3182/"
              target="_blank"
            >
              {/* <FontAwesomeIcon icon={faEnvelope} color="#cccccc" size="30" /> */}
              <FontAwesomeIcon
                className="iconsSocialLinks"
                icon={icons.linkedIn}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;

// [
//   fa0,
//   fa1,
//   fa2,
//   fa3,
//   fa4,
//   fa5,
//   fa6,
//   fa7,
//   fa8,
//   fa9,
//   faA,
//   faAd,
//   faAdd,
//   faAddressBook,
//   faAddressCard,
//   faAdjust,
//   faAirFreshener,
//   faAlignCenter,
//   faAlignJustify,
//   faAlignLeft,
//   faAlignRight,
//   faAllergies,
//   faAmbulance,
//   faAmericanSignLanguageInterpreting,
//   faAnchor,
//   faAnchorCircleCheck,
//   faAnchorCircleExclamation,
//   faAnchorCircleXmark,
//   faAnchorLock,
//   faAngleDoubleDown,
//   faAngleDoubleLeft,
//   faAngleDoubleRight,
//   faAngleDoubleUp,
//   faAngleDown,
//   faAngleLeft,
//   faAngleRight,
//   faAngleUp,
//   faAnglesDown,
//   faAnglesLeft,
//   faAnglesRight,
//   faAnglesUp,
//   faAngry,
//   faAnkh,
//   faAppleAlt,
//   faAppleWhole,
//   faArchive,
//   faArchway,
//   faAreaChart,
//   faArrowAltCircleDown,
//   faArrowAltCircleLeft,
//   faArrowAltCircleRight,
//   faArrowAltCircleUp,
//   faArrowCircleDown,
//   faArrowCircleLeft,
//   faArrowCircleRight,
//   faArrowCircleUp,
//   faArrowDown,
//   faArrowDown19,
//   faArrowDown91,
//   faArrowDownAZ,
//   faArrowDownLong,
//   faArrowDownShortWide,
//   faArrowDownUpAcrossLine,
//   faArrowDownUpLock,
//   faArrowDownWideShort,
//   faArrowDownZA,
//   faArrowLeft,
//   faArrowLeftLong,
//   faArrowLeftRotate,
//   faArrowPointer,
//   faArrowRight,
//   faArrowRightArrowLeft,
//   faArrowRightFromBracket,
//   faArrowRightFromFile,
//   faArrowRightLong,
//   faArrowRightRotate,
//   faArrowRightToBracket,
//   faArrowRightToCity,
//   faArrowRightToFile,
//   faArrowRotateBack,
//   faArrowRotateBackward,
//   faArrowRotateForward,
//   faArrowRotateLeft,
//   faArrowRotateRight,
//   faArrowTrendDown,
//   faArrowTrendUp,
//   faArrowTurnDown,
//   faArrowTurnRight,
//   faArrowTurnUp,
//   faArrowUp,
//   faArrowUp19,
//   faArrowUp91,
//   faArrowUpAZ,
//   faArrowUpFromBracket,
//   faArrowUpFromGroundWater,
//   faArrowUpFromWaterPump,
//   faArrowUpLong,
//   faArrowUpRightDots,
//   faArrowUpRightFromSquare,
//   faArrowUpShortWide,
//   faArrowUpWideShort,
//   faArrowUpZA,
//   faArrows,
//   faArrowsAlt,
//   faArrowsAltH,
//   faArrowsAltV,
//   faArrowsDownToLine,
//   faArrowsDownToPeople,
//   faArrowsH,
//   faArrowsLeftRight,
//   faArrowsLeftRightToLine,
//   faArrowsRotate,
//   faArrowsSpin,
//   faArrowsSplitUpAndLeft,
//   faArrowsToCircle,
//   faArrowsToDot,
//   faArrowsToEye,
//   faArrowsTurnRight,
//   faArrowsTurnToDots,
//   faArrowsUpDown,
//   faArrowsUpDownLeftRight,
//   faArrowsUpToLine,
//   faArrowsV,
//   faAslInterpreting,
//   faAssistiveListeningSystems,
//   faAsterisk,
//   faAt,
//   faAtlas,
//   faAtom,
//   faAudioDescription,
//   faAustralSign,
//   faAutomobile,
//   faAward,
//   faB,
//   faBaby,
//   faBabyCarriage,
//   faBackspace,
//   faBackward,
//   faBackwardFast,
//   faBackwardStep,
//   faBacon,
//   faBacteria,
//   faBacterium,
//   faBagShopping,
//   faBahai,
//   faBahtSign,
//   faBalanceScale,
//   faBalanceScaleLeft,
//   faBalanceScaleRight,
//   faBan,
//   faBanSmoking,
//   faBandAid,
//   faBandage,
//   faBangladeshiTakaSign,
//   faBank,
//   faBarChart,
//   faBarcode,
//   faBars,
//   faBarsProgress,
//   faBarsStaggered,
//   faBaseball,
//   faBaseballBall,
//   faBaseballBatBall,
//   faBasketShopping,
//   faBasketball,
//   faBasketballBall,
//   faBath,
//   faBathtub,
//   faBattery,
//   faBattery0,
//   faBattery2,
//   faBattery3,
//   faBattery4,
//   faBattery5,
//   faBatteryCar,
//   faBatteryEmpty,
//   faBatteryFull,
//   faBatteryHalf,
//   faBatteryQuarter,
//   faBatteryThreeQuarters,
//   faBed,
//   faBedPulse,
//   faBeer,
//   faBeerMugEmpty,
//   faBell,
//   faBellConcierge,
//   faBellSlash,
//   faBezierCurve,
//   faBible,
//   faBicycle,
//   faBiking,
//   faBinoculars,
//   faBiohazard,
//   faBirthdayCake,
//   faBitcoinSign,
//   faBlackboard,
//   faBlender,
//   faBlenderPhone,
//   faBlind,
//   faBlog,
//   faBold,
//   faBolt,
//   faBoltLightning,
//   faBomb,
//   faBone,
//   faBong,
//   faBook,
//   faBookAtlas,
//   faBookBible,
//   faBookBookmark,
//   faBookDead,
//   faBookJournalWhills,
//   faBookMedical,
//   faBookOpen,
//   faBookOpenReader,
//   faBookQuran,
//   faBookReader,
//   faBookSkull,
//   faBookTanakh,
//   faBookmark,
//   faBorderAll,
//   faBorderNone,
//   faBorderStyle,
//   faBorderTopLeft,
//   faBoreHole,
//   faBottleDroplet,
//   faBottleWater,
//   faBowlFood,
//   faBowlRice,
//   faBowlingBall,
//   faBox,
//   faBoxArchive,
//   faBoxOpen,
//   faBoxTissue,
//   faBoxes,
//   faBoxesAlt,
//   faBoxesPacking,
//   faBoxesStacked,
//   faBraille,
//   faBrain,
//   faBrazilianRealSign,
//   faBreadSlice,
//   faBridge,
//   faBridgeCircleCheck,
//   faBridgeCircleExclamation,
//   faBridgeCircleXmark,
//   faBridgeLock,
//   faBridgeWater,
//   faBriefcase,
//   faBriefcaseClock,
//   faBriefcaseMedical,
//   faBroadcastTower,
//   faBroom,
//   faBroomBall,
//   faBrush,
//   faBucket,
//   faBug,
//   faBugSlash,
//   faBugs,
//   faBuilding,
//   faBuildingCircleArrowRight,
//   faBuildingCircleCheck,
//   faBuildingCircleExclamation,
//   faBuildingCircleXmark,
//   faBuildingColumns,
//   faBuildingFlag,
//   faBuildingLock,
//   faBuildingNgo,
//   faBuildingShield,
//   faBuildingUn,
//   faBuildingUser,
//   faBuildingWheat,
//   faBullhorn,
//   faBullseye,
//   faBurger,
//   faBurn,
//   faBurst,
//   faBus,
//   faBusAlt,
//   faBusSimple,
//   faBusinessTime,
//   faC,
//   faCab,
//   faCableCar,
//   faCake,
//   faCakeCandles,
//   faCalculator,
//   faCalendar,
//   faCalendarAlt,
//   faCalendarCheck,
//   faCalendarDay,
//   faCalendarDays,
//   faCalendarMinus,
//   faCalendarPlus,
//   faCalendarTimes,
//   faCalendarWeek,
//   faCalendarXmark,
//   faCamera,
//   faCameraAlt,
//   faCameraRetro,
//   faCameraRotate,
//   faCampground,
//   faCancel,
//   faCandyCane,
//   faCannabis,
//   faCapsules,
//   faCar,
//   faCarAlt,
//   faCarBattery,
//   faCarBurst,
//   faCarCrash,
//   faCarOn,
//   faCarRear,
//   faCarSide,
//   faCarTunnel,
//   faCaravan,
//   faCaretDown,
//   faCaretLeft,
//   faCaretRight,
//   faCaretSquareDown,
//   faCaretSquareLeft,
//   faCaretSquareRight,
//   faCaretSquareUp,
//   faCaretUp,
//   faCarriageBaby,
//   faCarrot,
//   faCartArrowDown,
//   faCartFlatbed,
//   faCartFlatbedSuitcase,
//   faCartPlus,
//   faCartShopping,
//   faCashRegister,
//   faCat,
//   faCediSign,
//   faCentSign,
//   faCertificate,
//   faChain,
//   faChainBroken,
//   faChainSlash,
//   faChair,
//   faChalkboard,
//   faChalkboardTeacher,
//   faChalkboardUser,
//   faChampagneGlasses,
//   faChargingStation,
//   faChartArea,
//   faChartBar,
//   faChartColumn,
//   faChartGantt,
//   faChartLine,
//   faChartPie,
//   faChartSimple,
//   faCheck,
//   faCheckCircle,
//   faCheckDouble,
//   faCheckSquare,
//   faCheckToSlot,
//   faCheese,
//   faChess,
//   faChessBishop,
//   faChessBoard,
//   faChessKing,
//   faChessKnight,
//   faChessPawn,
//   faChessQueen,
//   faChessRook,
//   faChevronCircleDown,
//   faChevronCircleLeft,
//   faChevronCircleRight,
//   faChevronCircleUp,
//   faChevronDown,
//   faChevronLeft,
//   faChevronRight,
//   faChevronUp,
//   faChild,
//   faChildCombatant,
//   faChildDress,
//   faChildReaching,
//   faChildRifle,
//   faChildren,
//   faChurch,
//   faCircle,
//   faCircleArrowDown,
//   faCircleArrowLeft,
//   faCircleArrowRight,
//   faCircleArrowUp,
//   faCircleCheck,
//   faCircleChevronDown,
//   faCircleChevronLeft,
//   faCircleChevronRight,
//   faCircleChevronUp,
//   faCircleDollarToSlot,
//   faCircleDot,
//   faCircleDown,
//   faCircleExclamation,
//   faCircleH,
//   faCircleHalfStroke,
//   faCircleInfo,
//   faCircleLeft,
//   faCircleMinus,
//   faCircleNodes,
//   faCircleNotch,
//   faCirclePause,
//   faCirclePlay,
//   faCirclePlus,
//   faCircleQuestion,
//   faCircleRadiation,
//   faCircleRight,
//   faCircleStop,
//   faCircleUp,
//   faCircleUser,
//   faCircleXmark,
//   faCity,
//   faClapperboard,
//   faClinicMedical,
//   faClipboard,
//   faClipboardCheck,
//   faClipboardList,
//   faClipboardQuestion,
//   faClipboardUser,
//   faClock,
//   faClockFour,
//   faClockRotateLeft,
//   faClone,
//   faClose,
//   faClosedCaptioning,
//   faCloud,
//   faCloudArrowDown,
//   faCloudArrowUp,
//   faCloudBolt,
//   faCloudDownload,
//   faCloudDownloadAlt,
//   faCloudMeatball,
//   faCloudMoon,
//   faCloudMoonRain,
//   faCloudRain,
//   faCloudShowersHeavy,
//   faCloudShowersWater,
//   faCloudSun,
//   faCloudSunRain,
//   faCloudUpload,
//   faCloudUploadAlt,
//   faClover,
//   faCny,
//   faCocktail,
//   faCode,
//   faCodeBranch,
//   faCodeCommit,
//   faCodeCompare,
//   faCodeFork,
//   faCodeMerge,
//   faCodePullRequest,
//   faCoffee,
//   faCog,
//   faCogs,
//   faCoins,
//   faColonSign,
//   faColumns,
//   faComment,
//   faCommentAlt,
//   faCommentDollar,
//   faCommentDots,
//   faCommentMedical,
//   faCommentSlash,
//   faCommentSms,
//   faCommenting,
//   faComments,
//   faCommentsDollar,
//   faCompactDisc,
//   faCompass,
//   faCompassDrafting,
//   faCompress,
//   faCompressAlt,
//   faCompressArrowsAlt,
//   faComputer,
//   faComputerMouse,
//   faConciergeBell,
//   faContactBook,
//   faContactCard,
//   faCookie,
//   faCookieBite,
//   faCopy,
//   faCopyright,
//   faCouch,
//   faCow,
//   faCreditCard,
//   faCreditCardAlt,
//   faCrop,
//   faCropAlt,
//   faCropSimple,
//   faCross,
//   faCrosshairs,
//   faCrow,
//   faCrown,
//   faCrutch,
//   faCruzeiroSign,
//   faCube,
//   faCubes,
//   faCubesStacked,
//   faCut,
//   faCutlery,
//   faD,
//   faDashboard,
//   faDatabase,
//   faDeaf,
//   faDeafness,
//   faDedent,
//   faDeleteLeft,
//   faDemocrat,
//   faDesktop,
//   faDesktopAlt,
//   faDharmachakra,
//   faDiagnoses,
//   faDiagramNext,
//   faDiagramPredecessor,
//   faDiagramProject,
//   faDiagramSuccessor,
//   faDiamond,
//   faDiamondTurnRight,
//   faDice,
//   faDiceD20,
//   faDiceD6,
//   faDiceFive,
//   faDiceFour,
//   faDiceOne,
//   faDiceSix,
//   faDiceThree,
//   faDiceTwo,
//   faDigging,
//   faDigitalTachograph,
//   faDirections,
//   faDisease,
//   faDisplay,
//   faDivide,
//   faDizzy,
//   faDna,
//   faDog,
//   faDollar,
//   faDollarSign,
//   faDolly,
//   faDollyBox,
//   faDollyFlatbed,
//   faDonate,
//   faDongSign,
//   faDoorClosed,
//   faDoorOpen,
//   faDotCircle,
//   faDove,
//   faDownLeftAndUpRightToCenter,
//   faDownLong,
//   faDownload,
//   faDraftingCompass,
//   faDragon,
//   faDrawPolygon,
//   faDriversLicense,
//   faDroplet,
//   faDropletSlash,
//   faDrum,
//   faDrumSteelpan,
//   faDrumstickBite,
//   faDumbbell,
//   faDumpster,
//   faDumpsterFire,
//   faDungeon,
//   faE,
//   faEarDeaf,
//   faEarListen,
//   faEarth,
//   faEarthAfrica,
//   faEarthAmerica,
//   faEarthAmericas,
//   faEarthAsia,
//   faEarthEurope,
//   faEarthOceania,
//   faEdit,
//   faEgg,
//   faEject,
//   faElevator,
//   faEllipsis,
//   faEllipsisH,
//   faEllipsisV,
//   faEllipsisVertical,
//   faEnvelope,
//   faEnvelopeCircleCheck,
//   faEnvelopeOpen,
//   faEnvelopeOpenText,
//   faEnvelopeSquare,
//   faEnvelopesBulk,
//   faEquals,
//   faEraser,
//   faEthernet,
//   faEur,
//   faEuro,
//   faEuroSign,
//   faExchange,
//   faExchangeAlt,
//   faExclamation,
//   faExclamationCircle,
//   faExclamationTriangle,
//   faExpand,
//   faExpandAlt,
//   faExpandArrowsAlt,
//   faExplosion,
//   faExternalLink,
//   faExternalLinkAlt,
//   faExternalLinkSquare,
//   faExternalLinkSquareAlt,
//   faEye,
//   faEyeDropper,
//   faEyeDropperEmpty,
//   faEyeLowVision,
//   faEyeSlash,
//   faEyedropper,
//   faF,
//   faFaceAngry,
//   faFaceDizzy,
//   faFaceFlushed,
//   faFaceFrown,
//   faFaceFrownOpen,
//   faFaceGrimace,
//   faFaceGrin,
//   faFaceGrinBeam,
//   faFaceGrinBeamSweat,
//   faFaceGrinHearts,
//   faFaceGrinSquint,
//   faFaceGrinSquintTears,
//   faFaceGrinStars,
//   faFaceGrinTears,
//   faFaceGrinTongue,
//   faFaceGrinTongueSquint,
//   faFaceGrinTongueWink,
//   faFaceGrinWide,
//   faFaceGrinWink,
//   faFaceKiss,
//   faFaceKissBeam,
//   faFaceKissWinkHeart,
//   faFaceLaugh,
//   faFaceLaughBeam,
//   faFaceLaughSquint,
//   faFaceLaughWink,
//   faFaceMeh,
//   faFaceMehBlank,
//   faFaceRollingEyes,
//   faFaceSadCry,
//   faFaceSadTear,
//   faFaceSmile,
//   faFaceSmileBeam,
//   faFaceSmileWink,
//   faFaceSurprise,
//   faFaceTired,
//   faFan,
//   faFastBackward,
//   faFastForward,
//   faFaucet,
//   faFaucetDrip,
//   faFax,
//   faFeather,
//   faFeatherAlt,
//   faFeatherPointed,
//   faFeed,
//   faFemale,
//   faFerry,
//   faFighterJet,
//   faFile,
//   faFileAlt,
//   faFileArchive,
//   faFileArrowDown,
//   faFileArrowUp,
//   faFileAudio,
//   faFileCircleCheck,
//   faFileCircleExclamation,
//   faFileCircleMinus,
//   faFileCirclePlus,
//   faFileCircleQuestion,
//   faFileCircleXmark,
//   faFileClipboard,
//   faFileCode,
//   faFileContract,
//   faFileCsv,
//   faFileDownload,
//   faFileEdit,
//   faFileExcel,
//   faFileExport,
//   faFileImage,
//   faFileImport,
//   faFileInvoice,
//   faFileInvoiceDollar,
//   faFileLines,
//   faFileMedical,
//   faFileMedicalAlt,
//   faFilePdf,
//   faFilePen,
//   faFilePowerpoint,
//   faFilePrescription,
//   faFileShield,
//   faFileSignature,
//   faFileText,
//   faFileUpload,
//   faFileVideo,
//   faFileWaveform,
//   faFileWord,
//   faFileZipper,
//   faFill,
//   faFillDrip,
//   faFilm,
//   faFilter,
//   faFilterCircleDollar,
//   faFilterCircleXmark,
//   faFingerprint,
//   faFire,
//   faFireAlt,
//   faFireBurner,
//   faFireExtinguisher,
//   faFireFlameCurved,
//   faFireFlameSimple,
//   faFirstAid,
//   faFish,
//   faFishFins,
//   faFistRaised,
//   faFlag,
//   faFlagCheckered,
//   faFlagUsa,
//   faFlask,
//   faFlaskVial,
//   faFloppyDisk,
//   faFlorinSign,
//   faFlushed,
//   faFolder,
//   faFolderBlank,
//   faFolderClosed,
//   faFolderMinus,
//   faFolderOpen,
//   faFolderPlus,
//   faFolderTree,
//   faFont,
//   faFontAwesome,
//   faFontAwesomeFlag,
//   faFontAwesomeLogoFull,
//   faFootball,
//   faFootballBall,
//   faForward,
//   faForwardFast,
//   faForwardStep,
//   faFrancSign,
//   faFrog,
//   faFrown,
//   faFrownOpen,
//   faFunnelDollar,
//   faFutbol,
//   faFutbolBall,
//   faG,
//   faGamepad,
//   faGasPump,
//   faGauge,
//   faGaugeHigh,
//   faGaugeMed,
//   faGaugeSimple,
//   faGaugeSimpleHigh,
//   faGaugeSimpleMed,
//   faGavel,
//   faGbp,
//   faGear,
//   faGears,
//   faGem,
//   faGenderless,
//   faGhost,
//   faGift,
//   faGifts,
//   faGlassCheers,
//   faGlassMartini,
//   faGlassMartiniAlt,
//   faGlassWater,
//   faGlassWaterDroplet,
//   faGlassWhiskey,
//   faGlasses,
//   faGlobe,
//   faGlobeAfrica,
//   faGlobeAmericas,
//   faGlobeAsia,
//   faGlobeEurope,
//   faGlobeOceania,
//   faGolfBall,
//   faGolfBallTee,
//   faGopuram,
//   faGraduationCap,
//   faGreaterThan,
//   faGreaterThanEqual,
//   faGrimace,
//   faGrin,
//   faGrinAlt,
//   faGrinBeam,
//   faGrinBeamSweat,
//   faGrinHearts,
//   faGrinSquint,
//   faGrinSquintTears,
//   faGrinStars,
//   faGrinTears,
//   faGrinTongue,
//   faGrinTongueSquint,
//   faGrinTongueWink,
//   faGrinWink,
//   faGrip,
//   faGripHorizontal,
//   faGripLines,
//   faGripLinesVertical,
//   faGripVertical,
//   faGroupArrowsRotate,
//   faGuaraniSign,
//   faGuitar,
//   faGun,
//   faH,
//   faHSquare,
//   faHamburger,
//   faHammer,
//   faHamsa,
//   faHand,
//   faHandBackFist,
//   faHandDots,
//   faHandFist,
//   faHandHolding,
//   faHandHoldingDollar,
//   faHandHoldingDroplet,
//   faHandHoldingHand,
//   faHandHoldingHeart,
//   faHandHoldingMedical,
//   faHandHoldingUsd,
//   faHandHoldingWater,
//   faHandLizard,
//   faHandMiddleFinger,
//   faHandPaper,
//   faHandPeace,
//   faHandPointDown,
//   faHandPointLeft,
//   faHandPointRight,
//   faHandPointUp,
//   faHandPointer,
//   faHandRock,
//   faHandScissors,
//   faHandSparkles,
//   faHandSpock,
//   faHandcuffs,
//   faHands,
//   faHandsAmericanSignLanguageInterpreting,
//   faHandsAslInterpreting,
//   faHandsBound,
//   faHandsBubbles,
//   faHandsClapping,
//   faHandsHelping,
//   faHandsHolding,
//   faHandsHoldingChild,
//   faHandsHoldingCircle,
//   faHandsPraying,
//   faHandsWash,
//   faHandshake,
//   faHandshakeAlt,
//   faHandshakeAltSlash,
//   faHandshakeAngle,
//   faHandshakeSimple,
//   faHandshakeSimpleSlash,
//   faHandshakeSlash,
//   faHanukiah,
//   faHardDrive,
//   faHardHat,
//   faHardOfHearing,
//   faHashtag,
//   faHatCowboy,
//   faHatCowboySide,
//   faHatHard,
//   faHatWizard,
//   faHaykal,
//   faHdd,
//   faHeadSideCough,
//   faHeadSideCoughSlash,
//   faHeadSideMask,
//   faHeadSideVirus,
//   faHeader,
//   faHeading,
//   faHeadphones,
//   faHeadphonesAlt,
//   faHeadphonesSimple,
//   faHeadset,
//   faHeart,
//   faHeartBroken,
//   faHeartCircleBolt,
//   faHeartCircleCheck,
//   faHeartCircleExclamation,
//   faHeartCircleMinus,
//   faHeartCirclePlus,
//   faHeartCircleXmark,
//   faHeartCrack,
//   faHeartMusicCameraBolt,
//   faHeartPulse,
//   faHeartbeat,
//   faHelicopter,
//   faHelicopterSymbol,
//   faHelmetSafety,
//   faHelmetUn,
//   faHighlighter,
//   faHiking,
//   faHillAvalanche,
//   faHillRockslide,
//   faHippo,
//   faHistory,
//   faHockeyPuck,
//   faHollyBerry,
//   faHome,
//   faHomeAlt,
//   faHomeLg,
//   faHomeLgAlt,
//   faHomeUser,
//   faHorse,
//   faHorseHead,
//   faHospital,
//   faHospitalAlt,
//   faHospitalSymbol,
//   faHospitalUser,
//   faHospitalWide,
//   faHotTub,
//   faHotTubPerson,
//   faHotdog,
//   faHotel,
//   faHourglass,
//   faHourglass1,
//   faHourglass2,
//   faHourglass3,
//   faHourglassEmpty,
//   faHourglassEnd,
//   faHourglassHalf,
//   faHourglassStart,
//   faHouse,
//   faHouseChimney,
//   faHouseChimneyCrack,
//   faHouseChimneyMedical,
//   faHouseChimneyUser,
//   faHouseChimneyWindow,
//   faHouseCircleCheck,
//   faHouseCircleExclamation,
//   faHouseCircleXmark,
//   faHouseCrack,
//   faHouseDamage,
//   faHouseFire,
//   faHouseFlag,
//   faHouseFloodWater,
//   faHouseFloodWaterCircleArrowRight,
//   faHouseLaptop,
//   faHouseLock,
//   faHouseMedical,
//   faHouseMedicalCircleCheck,
//   faHouseMedicalCircleExclamation,
//   faHouseMedicalCircleXmark,
//   faHouseMedicalFlag,
//   faHouseSignal,
//   faHouseTsunami,
//   faHouseUser,
//   faHryvnia,
//   faHryvniaSign,
//   faHurricane,
//   faI,
//   faICursor,
//   faIceCream,
//   faIcicles,
//   faIcons,
//   faIdBadge,
//   faIdCard,
//   faIdCardAlt,
//   faIdCardClip,
//   faIgloo,
//   faIls,
//   faImage,
//   faImagePortrait,
//   faImages,
//   faInbox,
//   faIndent,
//   faIndianRupee,
//   faIndianRupeeSign,
//   faIndustry,
//   faInfinity,
//   faInfo,
//   faInfoCircle,
//   faInr,
//   faInstitution,
//   faItalic,
//   faJ,
//   faJar,
//   faJarWheat,
//   faJedi,
//   faJetFighter,
//   faJetFighterUp,
//   faJoint,
//   faJournalWhills,
//   faJpy,
//   faJugDetergent,
//   faK,
//   faKaaba,
//   faKey,
//   faKeyboard,
//   faKhanda,
//   faKipSign,
//   faKiss,
//   faKissBeam,
//   faKissWinkHeart,
//   faKitMedical,
//   faKitchenSet,
//   faKiwiBird,
//   faKrw,
//   faL,
//   faLadderWater,
//   faLandMineOn,
//   faLandmark,
//   faLandmarkAlt,
//   faLandmarkDome,
//   faLandmarkFlag,
//   faLanguage,
//   faLaptop,
//   faLaptopCode,
//   faLaptopFile,
//   faLaptopHouse,
//   faLaptopMedical,
//   faLariSign,
//   faLaugh,
//   faLaughBeam,
//   faLaughSquint,
//   faLaughWink,
//   faLayerGroup,
//   faLeaf,
//   faLeftLong,
//   faLeftRight,
//   faLegal,
//   faLemon,
//   faLessThan,
//   faLessThanEqual,
//   faLevelDown,
//   faLevelDownAlt,
//   faLevelUp,
//   faLevelUpAlt,
//   faLifeRing,
//   faLightbulb,
//   faLineChart,
//   faLinesLeaning,
//   faLink,
//   faLinkSlash,
//   faLiraSign,
//   faList,
//   faList12,
//   faListAlt,
//   faListCheck,
//   faListDots,
//   faListNumeric,
//   faListOl,
//   faListSquares,
//   faListUl,
//   faLitecoinSign,
//   faLocation,
//   faLocationArrow,
//   faLocationCrosshairs,
//   faLocationDot,
//   faLocationPin,
//   faLocationPinLock,
//   faLock,
//   faLockOpen,
//   faLocust,
//   faLongArrowAltDown,
//   faLongArrowAltLeft,
//   faLongArrowAltRight,
//   faLongArrowAltUp,
//   faLongArrowDown,
//   faLongArrowLeft,
//   faLongArrowRight,
//   faLongArrowUp,
//   faLowVision,
//   faLuggageCart,
//   faLungs,
//   faLungsVirus,
//   faM,
//   faMagic,
//   faMagicWandSparkles,
//   faMagnet,
//   faMagnifyingGlass,
//   faMagnifyingGlassArrowRight,
//   faMagnifyingGlassChart,
//   faMagnifyingGlassDollar,
//   faMagnifyingGlassLocation,
//   faMagnifyingGlassMinus,
//   faMagnifyingGlassPlus,
//   faMailBulk,
//   faMailForward,
//   faMailReply,
//   faMailReplyAll,
//   faMale,
//   faManatSign,
//   faMap,
//   faMapLocation,
//   faMapLocationDot,
//   faMapMarked,
//   faMapMarkedAlt,
//   faMapMarker,
//   faMapMarkerAlt,
//   faMapPin,
//   faMapSigns,
//   faMarker,
//   faMars,
//   faMarsAndVenus,
//   faMarsAndVenusBurst,
//   faMarsDouble,
//   faMarsStroke,
//   faMarsStrokeH,
//   faMarsStrokeRight,
//   faMarsStrokeUp,
//   faMarsStrokeV,
//   faMartiniGlass,
//   faMartiniGlassCitrus,
//   faMartiniGlassEmpty,
//   faMask,
//   faMaskFace,
//   faMaskVentilator,
//   faMasksTheater,
//   faMattressPillow,
//   faMaximize,
//   faMedal,
//   faMedkit,
//   faMeh,
//   faMehBlank,
//   faMehRollingEyes,
//   faMemory,
//   faMenorah,
//   faMercury,
//   faMessage,
//   faMeteor,
//   faMicrochip,
//   faMicrophone,
//   faMicrophoneAlt,
//   faMicrophoneAltSlash,
//   faMicrophoneLines,
//   faMicrophoneLinesSlash,
//   faMicrophoneSlash,
//   faMicroscope,
//   faMillSign,
//   faMinimize,
//   faMinus,
//   faMinusCircle,
//   faMinusSquare,
//   faMitten,
//   faMobile,
//   faMobileAlt,
//   faMobileAndroid,
//   faMobileAndroidAlt,
//   faMobileButton,
//   faMobilePhone,
//   faMobileRetro,
//   faMobileScreen,
//   faMobileScreenButton,
//   faMoneyBill,
//   faMoneyBill1,
//   faMoneyBill1Wave,
//   faMoneyBillAlt,
//   faMoneyBillTransfer,
//   faMoneyBillTrendUp,
//   faMoneyBillWave,
//   faMoneyBillWaveAlt,
//   faMoneyBillWheat,
//   faMoneyBills,
//   faMoneyCheck,
//   faMoneyCheckAlt,
//   faMoneyCheckDollar,
//   faMonument,
//   faMoon,
//   faMortarBoard,
//   faMortarPestle,
//   faMosque,
//   faMosquito,
//   faMosquitoNet,
//   faMotorcycle,
//   faMound,
//   faMountain,
//   faMountainCity,
//   faMountainSun,
//   faMouse,
//   faMousePointer,
//   faMugHot,
//   faMugSaucer,
//   faMultiply,
//   faMuseum,
//   faMusic,
//   faN,
//   faNairaSign,
//   faNavicon,
//   faNetworkWired,
//   faNeuter,
//   faNewspaper,
//   faNotEqual,
//   faNotdef,
//   faNoteSticky,
//   faNotesMedical,
//   faO,
//   faObjectGroup,
//   faObjectUngroup,
//   faOilCan,
//   faOilWell,
//   faOm,
//   faOtter,
//   faOutdent,
//   faP,
//   faPager,
//   faPaintBrush,
//   faPaintRoller,
//   faPaintbrush,
//   faPalette,
//   faPallet,
//   faPanorama,
//   faPaperPlane,
//   faPaperclip,
//   faParachuteBox,
//   faParagraph,
//   faParking,
//   faPassport,
//   faPastafarianism,
//   faPaste,
//   faPause,
//   faPauseCircle,
//   faPaw,
//   faPeace,
//   faPen,
//   faPenAlt,
//   faPenClip,
//   faPenFancy,
//   faPenNib,
//   faPenRuler,
//   faPenSquare,
//   faPenToSquare,
//   faPencil,
//   faPencilAlt,
//   faPencilRuler,
//   faPencilSquare,
//   faPeopleArrows,
//   faPeopleArrowsLeftRight,
//   faPeopleCarry,
//   faPeopleCarryBox,
//   faPeopleGroup,
//   faPeopleLine,
//   faPeoplePulling,
//   faPeopleRobbery,
//   faPeopleRoof,
//   faPepperHot,
//   faPercent,
//   faPercentage,
//   faPerson,
//   faPersonArrowDownToLine,
//   faPersonArrowUpFromLine,
//   faPersonBiking,
//   faPersonBooth,
//   faPersonBreastfeeding,
//   faPersonBurst,
//   faPersonCane,
//   faPersonChalkboard,
//   faPersonCircleCheck,
//   faPersonCircleExclamation,
//   faPersonCircleMinus,
//   faPersonCirclePlus,
//   faPersonCircleQuestion,
//   faPersonCircleXmark,
//   faPersonDigging,
//   faPersonDotsFromLine,
//   faPersonDress,
//   faPersonDressBurst,
//   faPersonDrowning,
//   faPersonFalling,
//   faPersonFallingBurst,
//   faPersonHalfDress,
//   faPersonHarassing,
//   faPersonHiking,
//   faPersonMilitaryPointing,
//   faPersonMilitaryRifle,
//   faPersonMilitaryToPerson,
//   faPersonPraying,
//   faPersonPregnant,
//   faPersonRays,
//   faPersonRifle,
//   faPersonRunning,
//   faPersonShelter,
//   faPersonSkating,
//   faPersonSkiing,
//   faPersonSkiingNordic,
//   faPersonSnowboarding,
//   faPersonSwimming,
//   faPersonThroughWindow,
//   faPersonWalking,
//   faPersonWalkingArrowLoopLeft,
//   faPersonWalkingArrowRight,
//   faPersonWalkingDashedLineArrowRight,
//   faPersonWalkingLuggage,
//   faPersonWalkingWithCane,
//   faPesetaSign,
//   faPesoSign,
//   faPhone,
//   faPhoneAlt,
//   faPhoneFlip,
//   faPhoneSlash,
//   faPhoneSquare,
//   faPhoneSquareAlt,
//   faPhoneVolume,
//   faPhotoFilm,
//   faPhotoVideo,
//   faPieChart,
//   faPiggyBank,
//   faPills,
//   faPingPongPaddleBall,
//   faPizzaSlice,
//   faPlaceOfWorship,
//   faPlane,
//   faPlaneArrival,
//   faPlaneCircleCheck,
//   faPlaneCircleExclamation,
//   faPlaneCircleXmark,
//   faPlaneDeparture,
//   faPlaneLock,
//   faPlaneSlash,
//   faPlaneUp,
//   faPlantWilt,
//   faPlateWheat,
//   faPlay,
//   faPlayCircle,
//   faPlug,
//   faPlugCircleBolt,
//   faPlugCircleCheck,
//   faPlugCircleExclamation,
//   faPlugCircleMinus,
//   faPlugCirclePlus,
//   faPlugCircleXmark,
//   faPlus,
//   faPlusCircle,
//   faPlusMinus,
//   faPlusSquare,
//   faPodcast,
//   faPoll,
//   faPollH,
//   faPoo,
//   faPooBolt,
//   faPooStorm,
//   faPoop,
//   faPortrait,
//   faPoundSign,
//   faPowerOff,
//   faPray,
//   faPrayingHands,
//   faPrescription,
//   faPrescriptionBottle,
//   faPrescriptionBottleAlt,
//   faPrescriptionBottleMedical,
//   faPrint,
//   faProcedures,
//   faProjectDiagram,
//   faPumpMedical,
//   faPumpSoap,
//   faPuzzlePiece,
//   faQ,
//   faQrcode,
//   faQuestion,
//   faQuestionCircle,
//   faQuidditch,
//   faQuidditchBroomBall,
//   faQuoteLeft,
//   faQuoteLeftAlt,
//   faQuoteRight,
//   faQuoteRightAlt,
//   faQuran,
//   faR,
//   faRadiation,
//   faRadiationAlt,
//   faRadio,
//   faRainbow,
//   faRandom,
//   faRankingStar,
//   faReceipt,
//   faRecordVinyl,
//   faRectangleAd,
//   faRectangleList,
//   faRectangleTimes,
//   faRectangleXmark,
//   faRecycle,
//   faRedo,
//   faRedoAlt,
//   faRefresh,
//   faRegistered,
//   faRemove,
//   faRemoveFormat,
//   faReorder,
//   faRepeat,
//   faReply,
//   faReplyAll,
//   faRepublican,
//   faRestroom,
//   faRetweet,
//   faRibbon,
//   faRightFromBracket,
//   faRightLeft,
//   faRightLong,
//   faRightToBracket,
//   faRing,
//   faRmb,
//   faRoad,
//   faRoadBarrier,
//   faRoadBridge,
//   faRoadCircleCheck,
//   faRoadCircleExclamation,
//   faRoadCircleXmark,
//   faRoadLock,
//   faRoadSpikes,
//   faRobot,
//   faRocket,
//   faRodAsclepius,
//   faRodSnake,
//   faRotate,
//   faRotateBack,
//   faRotateBackward,
//   faRotateForward,
//   faRotateLeft,
//   faRotateRight,
//   faRouble,
//   faRoute,
//   faRss,
//   faRssSquare,
//   faRub,
//   faRuble,
//   faRubleSign,
//   faRug,
//   faRuler,
//   faRulerCombined,
//   faRulerHorizontal,
//   faRulerVertical,
//   faRunning,
//   faRupee,
//   faRupeeSign,
//   faRupiahSign,
//   faS,
//   faSackDollar,
//   faSackXmark,
//   faSadCry,
//   faSadTear,
//   faSailboat,
//   faSatellite,
//   faSatelliteDish,
//   faSave,
//   faScaleBalanced,
//   faScaleUnbalanced,
//   faScaleUnbalancedFlip,
//   faSchool,
//   faSchoolCircleCheck,
//   faSchoolCircleExclamation,
//   faSchoolCircleXmark,
//   faSchoolFlag,
//   faSchoolLock,
//   faScissors,
//   faScrewdriver,
//   faScrewdriverWrench,
//   faScroll,
//   faScrollTorah,
//   faSdCard,
//   faSearch,
//   faSearchDollar,
//   faSearchLocation,
//   faSearchMinus,
//   faSearchPlus,
//   faSection,
//   faSeedling,
//   faServer,
//   faShapes,
//   faShare,
//   faShareAlt,
//   faShareAltSquare,
//   faShareFromSquare,
//   faShareNodes,
//   faShareSquare,
//   faSheetPlastic,
//   faShekel,
//   faShekelSign,
//   faSheqel,
//   faSheqelSign,
//   faShield,
//   faShieldAlt,
//   faShieldBlank,
//   faShieldCat,
//   faShieldDog,
//   faShieldHalved,
//   faShieldHeart,
//   faShieldVirus,
//   faShip,
//   faShippingFast,
//   faShirt,
//   faShoePrints,
//   faShop,
//   faShopLock,
//   faShopSlash,
//   faShoppingBag,
//   faShoppingBasket,
//   faShoppingCart,
//   faShower,
//   faShrimp,
//   faShuffle,
//   faShuttleSpace,
//   faShuttleVan,
//   faSign,
//   faSignHanging,
//   faSignIn,
//   faSignInAlt,
//   faSignLanguage,
//   faSignOut,
//   faSignOutAlt,
//   faSignal,
//   faSignal5,
//   faSignalPerfect,
//   faSignature,
//   faSigning,
//   faSignsPost,
//   faSimCard,
//   faSink,
//   faSitemap,
//   faSkating,
//   faSkiing,
//   faSkiingNordic,
//   faSkull,
//   faSkullCrossbones,
//   faSlash,
//   faSleigh,
//   faSliders,
//   faSlidersH,
//   faSmile,
//   faSmileBeam,
//   faSmileWink,
//   faSmog,
//   faSmoking,
//   faSmokingBan,
//   faSms,
//   faSnowboarding,
//   faSnowflake,
//   faSnowman,
//   faSnowplow,
//   faSoap,
//   faSoccerBall,
//   faSocks,
//   faSolarPanel,
//   faSort,
//   faSortAlphaAsc,
//   faSortAlphaDesc,
//   faSortAlphaDown,
//   faSortAlphaDownAlt,
//   faSortAlphaUp,
//   faSortAlphaUpAlt,
//   faSortAmountAsc,
//   faSortAmountDesc,
//   faSortAmountDown,
//   faSortAmountDownAlt,
//   faSortAmountUp,
//   faSortAmountUpAlt,
//   faSortAsc,
//   faSortDesc,
//   faSortDown,
//   faSortNumericAsc,
//   faSortNumericDesc,
//   faSortNumericDown,
//   faSortNumericDownAlt,
//   faSortNumericUp,
//   faSortNumericUpAlt,
//   faSortUp,
//   faSpa,
//   faSpaceShuttle,
//   faSpaghettiMonsterFlying,
//   faSpellCheck,
//   faSpider,
//   faSpinner,
//   faSplotch,
//   faSpoon,
//   faSprayCan,
//   faSprayCanSparkles,
//   faSprout,
//   faSquare,
//   faSquareArrowUpRight,
//   faSquareCaretDown,
//   faSquareCaretLeft,
//   faSquareCaretRight,
//   faSquareCaretUp,
//   faSquareCheck,
//   faSquareEnvelope,
//   faSquareFull,
//   faSquareH,
//   faSquareMinus,
//   faSquareNfi,
//   faSquareParking,
//   faSquarePen,
//   faSquarePersonConfined,
//   faSquarePhone,
//   faSquarePhoneFlip,
//   faSquarePlus,
//   faSquarePollHorizontal,
//   faSquarePollVertical,
//   faSquareRootAlt,
//   faSquareRootVariable,
//   faSquareRss,
//   faSquareShareNodes,
//   faSquareUpRight,
//   faSquareVirus,
//   faSquareXmark,
//   faStaffAesculapius,
//   faStaffSnake,
//   faStairs,
//   faStamp,
//   faStapler,
//   faStar,
//   faStarAndCrescent,
//   faStarHalf,
//   faStarHalfAlt,
//   faStarHalfStroke,
//   faStarOfDavid,
//   faStarOfLife,
//   faStepBackward,
//   faStepForward,
//   faSterlingSign,
//   faStethoscope,
//   faStickyNote,
//   faStop,
//   faStopCircle,
//   faStopwatch,
//   faStopwatch20,
//   faStore,
//   faStoreAlt,
//   faStoreAltSlash,
//   faStoreSlash,
//   faStream,
//   faStreetView,
//   faStrikethrough,
//   faStroopwafel,
//   faSubscript,
//   faSubtract,
//   faSubway,
//   faSuitcase,
//   faSuitcaseMedical,
//   faSuitcaseRolling,
//   faSun,
//   faSunPlantWilt,
//   faSuperscript,
//   faSurprise,
//   faSwatchbook,
//   faSwimmer,
//   faSwimmingPool,
//   faSynagogue,
//   faSync,
//   faSyncAlt,
//   faSyringe,
//   faT,
//   faTShirt,
//   faTable,
//   faTableCells,
//   faTableCellsLarge,
//   faTableColumns,
//   faTableList,
//   faTableTennis,
//   faTableTennisPaddleBall,
//   faTablet,
//   faTabletAlt,
//   faTabletAndroid,
//   faTabletButton,
//   faTabletScreenButton,
//   faTablets,
//   faTachographDigital,
//   faTachometer,
//   faTachometerAlt,
//   faTachometerAltAverage,
//   faTachometerAltFast,
//   faTachometerAverage,
//   faTachometerFast,
//   faTag,
//   faTags,
//   faTanakh,
//   faTape,
//   faTarp,
//   faTarpDroplet,
//   faTasks,
//   faTasksAlt,
//   faTaxi,
//   faTeeth,
//   faTeethOpen,
//   faTeletype,
//   faTelevision,
//   faTemperature0,
//   faTemperature1,
//   faTemperature2,
//   faTemperature3,
//   faTemperature4,
//   faTemperatureArrowDown,
//   faTemperatureArrowUp,
//   faTemperatureDown,
//   faTemperatureEmpty,
//   faTemperatureFull,
//   faTemperatureHalf,
//   faTemperatureHigh,
//   faTemperatureLow,
//   faTemperatureQuarter,
//   faTemperatureThreeQuarters,
//   faTemperatureUp,
//   faTenge,
//   faTengeSign,
//   faTent,
//   faTentArrowDownToLine,
//   faTentArrowLeftRight,
//   faTentArrowTurnLeft,
//   faTentArrowsDown,
//   faTents,
//   faTerminal,
//   faTextHeight,
//   faTextSlash,
//   faTextWidth,
//   faTh,
//   faThLarge,
//   faThList,
//   faTheaterMasks,
//   faThermometer,
//   faThermometer0,
//   faThermometer1,
//   faThermometer2,
//   faThermometer3,
//   faThermometer4,
//   faThermometerEmpty,
//   faThermometerFull,
//   faThermometerHalf,
//   faThermometerQuarter,
//   faThermometerThreeQuarters,
//   faThumbTack,
//   faThumbsDown,
//   faThumbsUp,
//   faThumbtack,
//   faThunderstorm,
//   faTicket,
//   faTicketAlt,
//   faTicketSimple,
//   faTimeline,
//   faTimes,
//   faTimesCircle,
//   faTimesRectangle,
//   faTimesSquare,
//   faTint,
//   faTintSlash,
//   faTired,
//   faToggleOff,
//   faToggleOn,
//   faToilet,
//   faToiletPaper,
//   faToiletPaperSlash,
//   faToiletPortable,
//   faToiletsPortable,
//   faToolbox,
//   faTools,
//   faTooth,
//   faTorah,
//   faToriiGate,
//   faTornado,
//   faTowerBroadcast,
//   faTowerCell,
//   faTowerObservation,
//   faTractor,
//   faTrademark,
//   faTrafficLight,
//   faTrailer,
//   faTrain,
//   faTrainSubway,
//   faTrainTram,
//   faTram,
//   faTransgender,
//   faTransgenderAlt,
//   faTrash,
//   faTrashAlt,
//   faTrashArrowUp,
//   faTrashCan,
//   faTrashCanArrowUp,
//   faTrashRestore,
//   faTrashRestoreAlt,
//   faTree,
//   faTreeCity,
//   faTriangleCircleSquare,
//   faTriangleExclamation,
//   faTrophy,
//   faTrowel,
//   faTrowelBricks,
//   faTruck,
//   faTruckArrowRight,
//   faTruckDroplet,
//   faTruckFast,
//   faTruckField,
//   faTruckFieldUn,
//   faTruckFront,
//   faTruckLoading,
//   faTruckMedical,
//   faTruckMonster,
//   faTruckMoving,
//   faTruckPickup,
//   faTruckPlane,
//   faTruckRampBox,
//   faTry,
//   faTshirt,
//   faTty,
//   faTurkishLira,
//   faTurkishLiraSign,
//   faTurnDown,
//   faTurnUp,
//   faTv,
//   faTvAlt,
//   faU,
//   faUmbrella,
//   faUmbrellaBeach,
//   faUnderline,
//   faUndo,
//   faUndoAlt,
//   faUniversalAccess,
//   faUniversity,
//   faUnlink,
//   faUnlock,
//   faUnlockAlt,
//   faUnlockKeyhole,
//   faUnsorted,
//   faUpDown,
//   faUpDownLeftRight,
//   faUpLong,
//   faUpRightAndDownLeftFromCenter,
//   faUpRightFromSquare,
//   faUpload,
//   faUsd,
//   faUser,
//   faUserAlt,
//   faUserAltSlash,
//   faUserAstronaut,
//   faUserCheck,
//   faUserCircle,
//   faUserClock,
//   faUserCog,
//   faUserDoctor,
//   faUserEdit,
//   faUserFriends,
//   faUserGear,
//   faUserGraduate,
//   faUserGroup,
//   faUserInjured,
//   faUserLarge,
//   faUserLargeSlash,
//   faUserLock,
//   faUserMd,
//   faUserMinus,
//   faUserNinja,
//   faUserNurse,
//   faUserPen,
//   faUserPlus,
//   faUserSecret,
//   faUserShield,
//   faUserSlash,
//   faUserTag,
//   faUserTie,
//   faUserTimes,
//   faUserXmark,
//   faUsers,
//   faUsersBetweenLines,
//   faUsersCog,
//   faUsersGear,
//   faUsersLine,
//   faUsersRays,
//   faUsersRectangle,
//   faUsersSlash,
//   faUsersViewfinder,
//   faUtensilSpoon,
//   faUtensils,
//   faV,
//   faVanShuttle,
//   faVault,
//   faVcard,
//   faVectorSquare,
//   faVenus,
//   faVenusDouble,
//   faVenusMars,
//   faVest,
//   faVestPatches,
//   faVial,
//   faVialCircleCheck,
//   faVialVirus,
//   faVials,
//   faVideo,
//   faVideoCamera,
//   faVideoSlash,
//   faVihara,
//   faVirus,
//   faVirusCovid,
//   faVirusCovidSlash,
//   faVirusSlash,
//   faViruses,
//   faVoicemail,
//   faVolcano,
//   faVolleyball,
//   faVolleyballBall,
//   faVolumeControlPhone,
//   faVolumeDown,
//   faVolumeHigh,
//   faVolumeLow,
//   faVolumeMute,
//   faVolumeOff,
//   faVolumeTimes,
//   faVolumeUp,
//   faVolumeXmark,
//   faVoteYea,
//   faVrCardboard,
//   faW,
//   faWalkieTalkie,
//   faWalking,
//   faWallet,
//   faWandMagic,
//   faWandMagicSparkles,
//   faWandSparkles,
//   faWarehouse,
//   faWarning,
//   faWater,
//   faWaterLadder,
//   faWaveSquare,
//   faWeight,
//   faWeightHanging,
//   faWeightScale,
//   faWheatAlt,
//   faWheatAwn,
//   faWheatAwnCircleExclamation,
//   faWheelchair,
//   faWheelchairAlt,
//   faWheelchairMove,
//   faWhiskeyGlass,
//   faWifi,
//   faWifi3,
//   faWifiStrong,
//   faWind,
//   faWindowClose,
//   faWindowMaximize,
//   faWindowMinimize,
//   faWindowRestore,
//   faWineBottle,
//   faWineGlass,
//   faWineGlassAlt,
//   faWineGlassEmpty,
//   faWon,
//   faWonSign,
//   faWorm,
//   faWrench,
//   faX,
//   faXRay,
//   faXmark,
//   faXmarkCircle,
//   faXmarkSquare,
//   faXmarksLines,
//   faY,
//   faYen,
//   faYenSign,
//   faYinYang,
//   faZ,
//   faZap,
//   fas,
//   prefix,
// ];
