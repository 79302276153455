import "./Css/project.css";
import React, { useState } from "react";
import { projects } from "./variables";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export function Projects({ icons }) {
  const { faLink } = icons;
  const [curPro, setProject] = useState(projects[0]);
  return (
    <div className="mainProjectContainer">
      <div className="heading ">
        <h1 className="headingText">Projects</h1>
        <div className="dash"></div>
      </div>

      <div className="proBox">
        <div className="proAllList">
          {projects.map((p) => (
            <button
              key={p.id}
              onClick={() => setProject(p)}
              className="proListItem"
            >
              <p className="proName">{p.title}</p>
              <div className="proItemGap"></div>
              <p className="proTech">{p.techStack}</p>
            </button>
          ))}
        </div>
        <div className="proDesc">
          <p className="proDescData">{curPro.desc}</p>
          {curPro.link && (
            <p className="proLinks">
              <a href={curPro.link} target="_blank">
                <FontAwesomeIcon className="iconsSocialLinks" icon={faLink} />
              </a>
            </p>
          )}
        </div>
      </div>
    </div>
  );
}

{
  /* <div className="projects">
        <div className="projectHeading">
          <span>Heading</span>
        </div>
        <div className="project"></div>
        <p className="proName">ProjectName</p>
        <p className="proTech">tech, technology, t</p>
        <p className="proDesc">
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Maiores
          doloremque enim autem distinctio praesentium modi ut, eos earum iste
          illum vitae itaque exercitationem facere, numquam nisi ipsam nam
          pariatur repellendus ex alias. A in natus perferendis debitis, ea
          architecto repellendus corporis, necessitatibus aliquid, quisquam
          expedita. Mollitia molestiae deleniti illum dolorum!
        </p>
      </div> */
}
