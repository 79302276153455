import React from "react";
import "./skills.css";
import { UsingSvg, LearningSvg, OtherSvg } from "./variables";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
// import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";
// import { Container, Navbar, Button, NavItem } from "react-bootstrap";

const Skills = () => {
  return (
    <React.Fragment>
      <div className="heading expHead">
        <h1 className="headingText">Skills</h1>
        <div className="dash"></div>
      </div>
      <div className="skillBoxMain">
        <div>
          <sapn className="skillSetHeading">Using</sapn>
          <div className="skillBoxSub">
            {UsingSvg.map((item) => {
              return (
                <div className="skill" key={item.name}>
                  {item.component()}
                  <p className="skillName">{item.name}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div>
          <span className="skillSetHeading">Learning</span>
          <div className="skillBoxSub">
            {LearningSvg.map((item) => {
              return (
                <div className="skill" key={item.name}>
                  {item.component()}
                  <p className="skillName">{item.name}</p>
                </div>
              );
            })}
          </div>
        </div>
        <div>
          <span className="skillSetHeading">Other</span>
          <div className="skillBoxSub">
            {OtherSvg.map((item) => {
              return (
                <div className="skill" key={item.name}>
                  {item.component()}
                  <p className="skillName">{item.name}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Skills;
