import "../Component/Css/education.css";
import React from "react";
import { colleges } from "./variables";
import { Github } from "./Icons/index.ts";

export function Education() {
  return (
    <div className="edMain">
      <div className="heading ">
        <h1 className="headingText">Education</h1>
        <div className="dash"></div>
      </div>
      <div className="edContentContainer">
        {colleges.map((item) => (
          <div className="edContent" key={item.id}>
            <div className="collegeImg">
              <Github />
            </div>
            <div className="collegeInfo">
              <p className="collegeName">
                <span>{item.clgName},</span> {item.clgLoc}
              </p>
              <p className="collegeYear" fontWeight="500">
                {item.year}
              </p>
              <p className="courceType">
                {item.cource} ({item.cgpa})
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
