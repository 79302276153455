import * as React from "react";
import type { SVGProps } from "react";
const SvgSelenium = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    preserveAspectRatio="xMidYMid"
    viewBox="-6.5 0 269 269"
    {...props}
  >
    <path
      fill="#2CB134"
      d="M234.153.003a1.72 1.72 0 0 1 1.533 2.683l-66.298 90.057a2.56 2.56 0 0 1-3.832 0l-23.377-26.059a2.713 2.713 0 0 0-4.215.384l-14.18 18.011a3 3 0 0 0 .384 3.066l42.154 43.687a2.56 2.56 0 0 0 3.833 0L252.93 38.71a1.77 1.77 0 0 1 3.066 1.15v226.485a1.809 1.809 0 0 1-1.917 1.916H1.92a1.809 1.809 0 0 1-1.917-1.916V1.92A1.809 1.809 0 0 1 1.92.003ZM169.005 152.91a41.817 41.817 0 0 0-42.538 42.92c0 26.443 19.161 43.305 44.07 43.305a52.758 52.758 0 0 0 32.192-9.964 2.372 2.372 0 0 0 .383-3.066l-6.898-10.347a2.319 2.319 0 0 0-3.066-.383 37.449 37.449 0 0 1-20.31 6.515c-13.414 0-21.845-8.431-23.377-18.395a.824.824 0 0 1 .766-.766h57.867a2.472 2.472 0 0 0 2.299-2.3v-2.3c0-26.825-16.862-45.22-41.388-45.22Zm-57.1-14.563a61.35 61.35 0 0 0-41.389-13.796c-26.059 0-42.154 15.329-42.154 33.724 0 41.771 63.615 28.358 63.615 47.903 0 6.131-6.132 12.263-19.161 12.263a48.156 48.156 0 0 1-33.34-13.03 2.56 2.56 0 0 0-3.833.383L25.68 219.59a2.372 2.372 0 0 0 .384 3.066c10.347 9.58 24.91 16.096 45.22 16.096 29.891 0 44.454-15.33 45.22-35.257 0-41.388-63.615-29.508-63.615-47.136 0-6.515 5.749-11.114 15.712-11.114a47.562 47.562 0 0 1 30.275 10.347 2.426 2.426 0 0 0 3.449-.383l9.964-13.413a2.426 2.426 0 0 0-.383-3.449Zm57.483 31.424c10.423-.583 19.422 7.227 20.311 17.629a.824.824 0 0 1-.766.766h-39.09a.824.824 0 0 1-.766-.766c1.282-10.198 10.035-17.795 20.311-17.629Z"
    />
  </svg>
);
export default SvgSelenium;
